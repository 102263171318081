@import './../scss/breakpoints.scss';

.App {
  display: flex;
  flex-direction: row;
  width: 100%;

  .main {
    width: 74%;
    z-index: 50;

    @include breakpoint(medium) {
      // width: calc(100% - 100px);
      width: 100%;
      margin-right: 15px;
      margin-left: 15px;
    }

    .section {
      position: relative;
      margin-top: 80px;
      min-height: 300px;

      @include breakpoint(medium) {
        margin-top: 135px;

        &:first-of-type {
          margin-top: 56px;
        }
      }

      .content {
        margin-top: 42px;
        margin-right: 53px;
        font-size: 12px;
        line-height: 1.8;
        font-family: 'Roboto';
        letter-spacing: 0.05em;

        @include breakpoint(medium) {
          margin-right: 0;
          margin-top: 30px;
        }

        .case {
          margin-bottom: 100px;

          @include breakpoint(medium) {
            &:last-of-type {
              margin-bottom: 0;
            }
          }

          video {
            width: 100%;
            height: auto;
            margin-bottom: 20px;

            @include breakpoint(medium) {
              margin-bottom: 8px;
            }
          }

          .title {
            font-family: 'Roboto';
            letter-spacing: 0.05em;
            margin-bottom: 4px;
            font-size: 16px;
          }

          .data {
            font-size: 12px;
            line-height: 1.8;
            font-family: 'Roboto';
            letter-spacing: 0.05em;
          }
        }
      }

      &.work {
        .anchor-wrapper {
          top: 0;

          .anchor {
            top: 167px;
          } 
        }
      }

      &.info {
        .anchor-wrapper {

          .anchor {
            top: 221px;
          } 
        }
      }

      &.contact {
        padding-bottom: calc(100vh - 207px);
        .anchor-wrapper {

          .anchor {
            top: 248px;
          } 
        }
      }

      .inner {
        display: flex;
        flex-direction: column;
      }

      .anchor-wrapper {
        position: absolute;
        bottom: 0;
        top: 12px;
      }

      .anchor {
        mix-blend-mode: difference;
        // z-index: 60;   
        width: 120px;
        height: 27px;
        background: blue;
        position: sticky;
        top: 200px;
        transform: translateX(-173px);

        @include breakpoint(medium) {
          // transform: translateX(-130px);
          display: none;
        }
      }
    }
  }

  .col {
    // min-height: 100%;
  }
}