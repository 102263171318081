@import './breakpoints.scss';

h1, h2, h3, h4, h5, h6 {
	margin: 0;
	font-weight: 500;
	letter-spacing: 2px;

}

h1 {
	@include breakpoint(medium) {
		font-size: 6vw;
	}
}