@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400&family=Roboto:ital,wght@0,400;1,300&display=swap);
html,body{width:100%}*{box-sizing:border-box}body{background:#f4f4f4;margin:0;font-family:'Roboto Slab', 'Oxygen', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}#root{display:flex;width:100%}h1,h2,h3,h4,h5,h6{margin:0;font-weight:500;letter-spacing:2px}@media screen and (max-width: 880px){h1{font-size:6vw}}

.App{display:flex;flex-direction:row;width:100%}.App .main{width:74%;z-index:50}@media screen and (max-width: 880px){.App .main{width:100%;margin-right:15px;margin-left:15px}}.App .main .section{position:relative;margin-top:80px;min-height:300px}@media screen and (max-width: 880px){.App .main .section{margin-top:135px}.App .main .section:first-of-type{margin-top:56px}}.App .main .section .content{margin-top:42px;margin-right:53px;font-size:12px;line-height:1.8;font-family:'Roboto';letter-spacing:0.05em}@media screen and (max-width: 880px){.App .main .section .content{margin-right:0;margin-top:30px}}.App .main .section .content .case{margin-bottom:100px}@media screen and (max-width: 880px){.App .main .section .content .case:last-of-type{margin-bottom:0}}.App .main .section .content .case video{width:100%;height:auto;margin-bottom:20px}@media screen and (max-width: 880px){.App .main .section .content .case video{margin-bottom:8px}}.App .main .section .content .case .title{font-family:'Roboto';letter-spacing:0.05em;margin-bottom:4px;font-size:16px}.App .main .section .content .case .data{font-size:12px;line-height:1.8;font-family:'Roboto';letter-spacing:0.05em}.App .main .section.work .anchor-wrapper{top:0}.App .main .section.work .anchor-wrapper .anchor{top:167px}.App .main .section.info .anchor-wrapper .anchor{top:221px}.App .main .section.contact{padding-bottom:calc(100vh - 207px)}.App .main .section.contact .anchor-wrapper .anchor{top:248px}.App .main .section .inner{display:flex;flex-direction:column}.App .main .section .anchor-wrapper{position:absolute;bottom:0;top:12px}.App .main .section .anchor{mix-blend-mode:difference;width:120px;height:27px;background:blue;position:-webkit-sticky;position:sticky;top:200px;transform:translateX(-173px)}@media screen and (max-width: 880px){.App .main .section .anchor{display:none}}


.nav{width:26%;background:#f4f4f4}@media screen and (max-width: 880px){.nav{width:100%;z-index:1000;position:fixed}}.nav .hero{position:fixed;width:26%;z-index:100;mix-blend-mode:difference;display:flex;flex-direction:column;align-items:flex-end;padding-right:65px}@media screen and (max-width: 880px){.nav .hero{width:100%;padding-right:20px;mix-blend-mode:inherit}}.nav .hero .logo{color:#fff;top:49px;position:absolute}@media screen and (max-width: 880px){.nav .hero .logo{top:20px;color:#000}}.nav .hero .logo .name{font-family:'Roboto';font-size:19px;text-align:right}.nav .hero .logo .byline{font-size:11px;font-weight:300;margin-top:3px}@media screen and (max-width: 880px){.nav .hero .logo .byline{font-size:9px}}.nav .hero .menu{font-family:'Roboto Slab';font-weight:300;font-size:15px;top:170px;list-style:none;text-align:right;mix-blend-mode:difference;position:absolute;margin:0;padding:0}@media screen and (max-width: 880px){.nav .hero .menu{display:none}}.nav .hero .menu li{color:#fff;padding:3px 0;position:relative;cursor:pointer;height:27px;margin:0;padding:0}.nav .hero .menu li:hover:after{content:'';position:absolute;height:27px;width:120px;top:-3px;right:-12px;background:#00ffdc;mix-blend-mode:difference}@media screen and (max-width: 880px){.nav .hero .menu li:hover:after{display:none}}


#clients .anchor-wrapper .anchor{top:194px}#clients .clientlist{margin:0;padding:0;list-style:none}




