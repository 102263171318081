#clients {
    .anchor-wrapper {

      .anchor {
        top: 194px;
      } 
    }

	.clientlist {
		margin: 0;
		padding: 0;
		list-style: none;

		li {

		}
	}
}